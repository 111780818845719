@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap);
:root {
    --dark: #202020;
    --theme : #92E3A9;
}
body {
    background-color: #202020;
    background-color: var(--dark);
    font-family: Nunito;
}
* {
    color: white;
}
a,a:hover {
    text-decoration: none;
    color: white;
}
.button {
    background-color: #92E3A9;
    background-color: var(--theme);
    color: #202020;
    color: var(--dark);
    font-size: 23px;
    padding: 10px 0;
    border-radius:100px;
    -webkit-border-radius:100px;
    -moz-border-radius:100px;
    -ms-border-radius:100px;
    -o-border-radius:100px;
}
.button:hover {
    background-color: #5cc279;
    color: white;
}
.theme {
    color: #92E3A9;
    color: var(--theme);
}
.glass {
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius:20px;
    position: relative;
    /* z-index: ; */
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    -ms-border-radius:20px;
    -o-border-radius:20px;
}
.glass:before {
    position: absolute;
    border-radius:20px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(135deg, rgba(255,255,255,0.63) -300%, rgba(128,128,128,0) 80%);
    content: "";
    z-index: -99;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    -ms-border-radius:20px;
    -o-border-radius:20px;
}
.glass *{
    position: relative;
    z-index: 9;
}
.headerList {
    display: flex;
    list-style: none;
    font-size: 19px;
    font-weight:600;
    margin: 0;
}
.headerList li {
    margin: 0 15px;
}
input.glass {
    color: white;
    font-size:20px;
}
input.glass:focus {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid rgba(255,255,255,0.3);
    color: white;
}
input.glass::-webkit-input-placeholder {
    color: white;
    font-size:20px;
}
input.glass:-ms-input-placeholder {
    color: white;
    font-size:20px;
}
input.glass::placeholder {
    color: white;
    font-size:20px;
}
.input-mdai {
    position: relative;
}
.mdai {
    position: absolute;
    top: 17px;
    right: 30px;
    height:30px;
}
.loading {
    height: 250px;
}
